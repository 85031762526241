<template>
    <section class="section__content-wrapper">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title"> {{unit.name}}</span>&nbsp;
                <span class="head__sub-title"> / {{ $t('Locator') }}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <UnitEdit_Locator :unit-id="unitId" />
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onSave"> {{ $t('buttons.Save') }} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{ $t('buttons.Cancel') }} </button>
        </section>
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    // import UnitEdit_Locator from "./UnitEdit_Locator.vue";
    // import UnitEdit_Options from "./UnitEdit_Options.vue";

    export default {
        name: "unitEdit",
        props: [
            'unitId',
        ],
        components: {
            // UnitEdit_Options,
            // UnitEdit_Locator,
            'UnitEdit_Locator': () => import('./UnitEdit_Locator.vue'),
            // 'UnitEdit_Options': () => import('./UnitEdit_Options.vue'),
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsByIds",
            ]),
            unit(){
                return this.unitsByIds[this.unitId]
            },
        },
        methods: {
            ...mapActions([
                "setSectionWidget",
            ]),
            onCancel(){
                this.$emit('close-dialog')
            },
            onSave(){
                this.$emit('close-dialog')
            }
        },
        created() {
        },
        updated() {
        },
        mounted() {
        }
    }
</script>

